/* eslint-disable max-len */
import React from "react";

import { useStaticQuery, graphql, Link } from "gatsby";

import SEO from "../components/Seo";
import BlogPage from "../components/template-parts/BlogPage";

function Acupuntura() {
  const { banner } = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "bgImage/bg-acupuntura.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const bannerPage = banner.childImageSharp.fluid;
  const content = {
    title: `Acupuntura`,
    featuredImage: bannerPage,
  };
  return (
    <>
      <SEO
        title="Acupuntura"
        keywords="helena campiglia, helenacampiglia, medica clinica geral, medicaclinicageral, acupuntura, infertilidade, mente e corpo, meditação, fmusp"
        description="A acupuntura é uma modalidade terapêutica que engloba, através da
        colocação de agulhas em diferentes partes do corpo, o tratamento e a
        prevenção de doenças e a promoção da saúde."
        type="article"
      />
      <BlogPage content={content}>
        <p>
          A acupuntura é uma modalidade terapêutica que engloba, através da colocação de agulhas em
          diferentes partes do corpo, o tratamento e a prevenção de doenças e a promoção da saúde.
        </p>
        <p>
          A acupuntura pode ser usada localmente, para o tratamento de dores regionais, ou à
          distância, para o tratamento de diversas condições clínicas, através dos meridianos
          correspondentes às região afetadas, seguindo, assim, os princípios da{" "}
          <Link to="/medicina-tradicional-chinesa">Medicina Tradicional Chinesa.</Link>
          &nbsp;
        </p>
        <h2>A acupuntura dói?</h2>
        <p>
          Dói um pouco, pois a passagem da agulha pela pele causa uma sensação de punção e um
          pequeno desconforto. Porém, basta olhar para a espessura das agulhas de acupuntura para se
          perceber que elas são muito mais finas que uma agulha de injeção; portanto, a dor
          resultante é muito menor.&nbsp;
        </p>
        <p>
          A agulha de acupuntura é como um fio de metal flexível e fino. Depois de perfurar a pele,
          a agulha pode ainda produzir uma sensação que os acupunturistas chamam de chegada do
          “Chi”, que é um pequeno choque ou sensação de peso no local. Após a colocação das agulhas,
          não há, em geral, mais dor e a pessoa pode relaxar e até dormir.
        </p>
        <h2>Quanto tempo dura a sessão?</h2>
        <p>
          A sessão de acupuntura poderia ser tão rápida que, em 20 minutos, o paciente estaria
          pronto para ir embora. Porém, o relaxamento, obtido com a inserção das agulhas, é
          altamente desejável. Assim, na maioria das vezes a sessão dura em torno de 30 a 50
          minutos. Ao usar técnicas diversas de acupuntura, tais quais a acupuntura auricular, as
          ventosas e o uso das agulhas na parte da frente do corpo e no dorso, o tempo da sessão
          pode aumentar um pouco.
        </p>
        <h2>Quanto tempo leva o tratamento?</h2>
        <p>
          Essa é uma pergunta com resposta extremamente variável, pois depende, em última análise,
          do desequilíbrio de cada um ao início do tratamento. O que se observa, em geral, é que os
          resultados da acupuntura costumam aparecer em torno da quinta ou sexta sessão,
          necessitando-se no mínimo de cerca de dez sessões para se ter uma boa idéia de para onde o
          paciente está caminhando. Em geral, pacientes jovens costumam ter respostas mais rápidas e
          mais duradouras ao tratamento. Alguns pacientes, por exemplo, podem chegar com queixa de
          dor de cabeça há mais de 15 anos, e após três meses de acupuntura, apresentar uma remissão
          completa dos sintomas, sem recidivas.
        </p>
        <h2>Os efeitos da acupuntura são duradouros?</h2>
        <p>
          Quando o paciente consegue mudar alguns aspectos prejudiciais do seu estilo de vida, tais
          como alimentação desequilibrada, estresse excessivo, falta de sono, de exercícios físicos
          ou postura incorreta, a duração dos efeitos da acupuntura é muito maior. Por exemplo, no
          tratamento de tendinites do braço, a dor pode desaparecer com a acupuntura, mas se o
          paciente continuar digitando no computador, usando uma cadeira muito baixa, um monitor
          muito alto, sem apoio para as mãos e sem intervalos para descanso, é certo que a dor vai
          voltar, pois digitar com má postura por horas a fio, é como correr uma maratona sem
          preparo físico.
        </p>
        <p>
          Em alguns casos, o que se observa é a tomada de consciência , pelo paciente de seu
          processo de adoecimento. Esse não é apenas um ato racional, mas, muitas vezes, intuitivo.
          Pequenas mudanças de comportamento podem gerar resultados impressionantes, contudo essas
          mudanças não são sempre óbvias ou claras. Através da acupuntura, apontam-se novos caminhos
          para o funcionamento físico e mental, porém a manutenção desses novos caminhos depende do
          momento, do ritmo e da percepção de cada um.
        </p>
        <h2>A acupuntura cura doenças graves?</h2>
        <p>
          A acupuntura pode ter efeitos notáveis na fisiologia do corpo. Alguns desses efeitos podem
          ser sentidos no funcionamento dos órgãos e sistemas, no relaxamento muscular e no controle
          da ansiedade. Entretanto, doenças que já estão enraizadas profundamente, com mudanças
          estruturais do organismo, dificilmente podem ser revertidas pela acupuntura. No máximo, a
          acupuntura poderá amenizar a dor, alguns dos sintomas e o sofrimento causados por tais
          doenças, porém sem a intenção de curá-las.
        </p>
        <h2>Acupuntura no Oriente e no Ocidente</h2>
        <p>
          No Oriente, a acupuntura faz parte da cultura e da tradição. Conta-se que os antigos
          médicos chineses eram pagos pela comunidade para evitar que seus integrantes adoecessem.
          Quando alguém daquela comunidade caia doente, o salário do médico era suspenso. Ou seja, o
          importante era promover a saúde, mais do que remediar as doenças. Hoje em dia, na China,
          tanto a Medicina Tradicional, quanto a Medicina Moderna, nos moldes ocidentais, têm seu
          lugar na sociedade. Os hospitais e ambulatórios costumam apresentar as duas modalidades, e
          os pacientes são encaminhados para uma ou para a outra conforme a necessidade. De qualquer
          modo, crianças, adultos e idosos são igualmente beneficiados pelo tratamento da
          acupuntura.
        </p>
        <p>
          No Ocidente, a acupuntura vem sendo assimilada rapidamente, tornando-se cada vez mais uma
          opção ou um complemento à medicina clássica. No Brasil, a acupuntura é especialidade
          médica, sendo já utilizada em grandes centros médicos. Nos EUA o National Institutes of
          Health (NIH) deu respaldo científico para seu uso no tratamento de diversas condições
          clínicas. Há experiências sendo feitas na Europa, usando a acupuntura para o tratamento de
          infertilidade feminina, cólicas menstruais e alterações do ciclo menstrual. O uso da
          acupuntura para o tratamento da dor é bastante difundido, pois acredita-se que a liberação
          de endorfinas e cortisol internos sejam os responsáveis pelo aumento do limiar da dor.
        </p>
        <p>
          A comprovação científica dos efeitos benéficos da acupuntura é apenas um dos aspectos de
          sua introdução no Ocidente. O interesse crescente nas antigas práticas da{" "}
          <Link to="/medicina-tradicional-chinesa">Medicina Tradicional Chinesa.</Link> mostra a
          necessidade de um entendimento global e integrado do ser humano no meio em que vive.
        </p>
      </BlogPage>
    </>
  );
}

export default Acupuntura;
