import React from "react";

import Layout from "../../Layout";
import MailChimpPage from "../../MailChimpPage";
import { Container, ImgBox, Title, BgImage, Content, Article } from "./styles";

// import SEO from "../../Seo";

function BlogPost({ children, content }) {
  return (
    <Layout>
      {/* <SEO title={title} description={excerpt} type="article" /> */}
      <Container>
        <ImgBox>
          <BgImage fluid={content.featuredImage}>
            <Title>{content.title}</Title>
          </BgImage>
        </ImgBox>

        <Content>
          <Article>{children}</Article>
          <MailChimpPage />
        </Content>
      </Container>
    </Layout>
  );
}

export default BlogPost;
